'use client';

import type { PropsWithChildren } from 'react';
import NoSSR from '../NoSSR';
import { FullStoryInit } from './FullStoryInit';

export function FullStoryWrapper(props: PropsWithChildren) {
	return (
		<>
			<NoSSR>
				<FullStoryInit />
			</NoSSR>
			{props.children}
		</>
	);
}
